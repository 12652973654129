// import './App.css';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Introduction from './components/Introduction';
import Navbar from './components/Navbar';
import Projects from './components/Projects';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <div>
        <Introduction/>
        <Projects/>
        <Experience/>
        <Contact/>
      </div>
      <Footer/>
      
    </div>
  );
}

export default App;

