import React from 'react'
import project1 from '../img/SoemCare.png'
import project2 from '../img/mufc-js.png'
// import project3 from '../img/Portfolio.png'
import project4 from '../img/Project4.png'

const Projects = () => {
  return (
     
    <div id='Projects' className="container-fluid">
          <section className='container'> 
          <h1 className='pt-2 ms-5 me-5'>Projects</h1>
          
            <div id='project-cards' className="d-flex gap-5 flex-wrap justify-content-center pt-2 pb-5">

              <div id='project1' class="card" style={{width:'18rem'}}>
                    <img src={project1} class="card-img-top" alt="..."/> 
                     <div class="card-body">
                            <h5 class="card-title">SoemCare</h5>
                            <p class="card-text">A JavaScript project for a dog-sitting service.</p>
                            <a href="https://soemcare.us/" target="_blank" rel="noreferrer" className="btn me-2">Demo</a>
                            <a href="https://github.com/rjohn151" target="_blank" rel="noreferrer" className="btn me-2">CodeBase</a>
                    </div>
              </div>
              <div id='project2' class="card" style={{width:'18rem'}}>
                    <img src={project2} class="card-img-top" alt="..."/> 
                     <div class="card-body">
                            <h5 class="card-title">Mufc API</h5>
                            <p class="card-text">A React project that will turn you into a Red Devil.</p>
                            <a href="https://adorable-melomakarona-a449e4.netlify.app/" target="_blank" rel="noreferrer" className="btn me-2">Demo</a>
                            <a href="https://github.com/rjohn151" target="_blank" rel="noreferrer" className="btn me-2">CodeBase</a>
                    </div>
              </div>
              {/* <div id='project3' class="card" style={{width:'18rem'}}>
                    <img src={project3} class="card-img-top" alt="..."/> 
                     <div class="card-body">
                            <h5 class="card-title">Portfolio</h5>
                            <p class="card-text">My portfolio showcasing frontend developer skills.</p>
                            <a href="https://ramonejohnson.com/" target="_blank" rel="noreferrer" className="btn me-2 btn-danger">Demo</a>
                            <a href="https://github.com/rjohn151" target="_blank" rel="noreferrer" className="btn me-2 btn-danger">CodeBase</a>
                    </div>
              </div> */}
              <div id='project4' class="card" style={{width:'18rem'}}>
                    <img src={project4} class="card-img-top" alt="..."/> 
                     <div class="card-body">
                            <h5 class="card-title">Stock API</h5>
                            <p class="card-text">A React project that scours the stock market.</p>
                            <a href="https://calm-churros-95843c.netlify.app/" target="_blank" rel="noreferrer" className="btn me-2" >Demo</a>
                            <a href="https://github.com/rjohn151" target="_blank" rel="noreferrer" className="btn me-2" >CodeBase</a>
                    </div>
              </div>

            </div>

           </section>
    </div>
    
  )
}

export default Projects

          