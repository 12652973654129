import React, { useState } from 'react'
import profile from '../img/profile_pic_square.jpg'
import { Link } from 'react-scroll'; //npm i react-scroll
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // npm install --save @fortawesome/react-fontawesome
import { faJs, faCss3, faHtml5, faReact, faBootstrap  } from '@fortawesome/free-brands-svg-icons'; /* npm install --save @fortawesome/free-brands-svg-icons   */
import { faDatabase, faWind } from '@fortawesome/free-solid-svg-icons'; // npm install --save @fortawesome/free-solid-svg-icons
import resume from './Ramone-Johnson-FE.pdf'

const Introduction = () => {
        const [show, setShow] = useState(false)
    return (
    <main className='container-fluid min-vh-100 d-flex align-items-center'>

        <div className='container'>
        <section id='section1' className="d-flex justify-content-center text-center">
                        <div className="d-flex gap-4 justify-content-center align-items-center flex-wrap">
                                <img src={profile}
                                    width="250px" 
                                    className="rounded-circle img-fluid mx-auto d-block"
                                    alt="Ramone"/>

                            <div className="align-items-center text-center">
                                    <h1 className="">Hello, my name is <span className="myName">Ramone Johnson</span>.</h1>
                                    <h2 className="">I'm a Front-End Developer. My passion is building dynamic websites.
                                    </h2>
    
                                 <div className='d-flex flex-wrap justify-content-center column-gap-5'>
                                    <Link to='Projects' className="projectBtn btn-background-slide text-decoration-none mt-3" spy={true} smooth={true} duration={100} >See my Projects</Link>

                                    <a className="projectBtn btn-background-slide text-decoration-none mt-3 ms-1" href={resume} without rel="noreferrer" target = "_blank">My Resume</a>

                                </div>
                            </div>
 
                        </div>    
            </section>
            
            <section id='section2' className="mx-5 my-5">
                <h3 className='header2'>Technologies I work with:</h3>
                    <div className='d-flex column-gap-5 row-gap-3 flex-wrap' >

                        <div id='tech1' className='d-flex align-items-center rounded px-3'>
                            <div>
                            <FontAwesomeIcon size='3x' icon={faDatabase}/>
                            </div>
                            {/* <p className='ms-2 text-white fw-bolder mb-0 pt-3 pb-3'> SQL</p> */}
                        </div>

                        <div id='tech2' className='d-flex align-items-center rounded px-3'>
                            <div>
                            <FontAwesomeIcon size='3x' className='tech1' icon={faHtml5}/>
                            </div>
                            {/* <p className='ms-2 text-white fw-bolder mb-0 pt-3 pb-3 '> HTML 5</p> */}
                        </div>

                        <div id='tech3' className='d-flex align-items-center rounded px-3 '>
                            <div>
                            <FontAwesomeIcon size='3x' icon={faCss3}/>
                            </div>
                            {/* <p className='ms-2 text-white fw-bolder mb-0 pt-3 pb-3'> CSS 3</p> */}
                        </div>

                        <div id='tech4' className='d-flex align-items-center rounded px-3 '>
                            <div>
                            <FontAwesomeIcon size='3x' icon={faReact}/>
                            </div>
                            {/* <p className='ms-2 text-white fw-bolder mb-0 pt-3 pb-3'>REACT</p> */}
                        </div>

                        <div id='tech5' className='d-flex align-items-center rounded px-3 '>
                            <div>
                            <FontAwesomeIcon size='3x' icon={faJs}/>
                            </div>
                            {/* <p className='ms-2 text-white fw-bolder mb-0 pt-3 pb-3'> JAVASCRIPT</p> */}
                        </div>
  
                        <div id='tech6' className='d-flex align-items-center rounded px-3'>
                            <div>
                                <FontAwesomeIcon size='3x' icon={faBootstrap}/>
                            </div>
                                {/* <p className='ms-2 text-white fw-bolder mb-0 pt-3 pb-3'>BOOTSTRAP</p> */}
                        </div>


                    </div>
                    <div className='About'>
                    <h1 className='about mt-5'>About Me</h1>
                            <h3 className='lh-base'>I'm a Junior Front-End Developer. My passion is utilizing Javascript, HTML, and CSS to customize and build dynamic websites. I am currently pushing myself to gain experience and ultimately become an experienced full-stack developer. <br />
                            In 2021 I graduated from Florida International University with my bachelors in Information Technology but as I was progressing through my IT path I discovered my love for creating webpages. I am now on the never ending journey of continuous learning so that I am able to achieve my goals. Check out my <a id='linkedIn' href="https://linkedin.com/in/ramone-johnson" className='' target="_blank" rel="noreferrer noopener">LinkedIn</a> to connect.
                            </h3>

                             <button type="button" className='btn-learning rounded py-2 px-2 mb-3' onClick={()=>setShow(!show)}>I am currently learning...</button>
                            {show && 
                                <ul>
                                     <li><mark>Node JS</mark> as I would like to continue building applications that uses real-time data and make it scalable by using Node modules such as Express JS.</li>
                                    <li><mark>MongoDB</mark> as it is flexible and dynamic but still allows efficient handling of unstructured data when comared to SQL.</li>
                                    <li><mark>C#</mark> continuing to always expand on what I have already learnt when it comes to Object-Orientated Programming.</li>
                                </ul>}
                    </div>

                    <div>
                            <h4 className='mt-5'>Learn more about me</h4>
                            <button type="button" id='modal' class="btn" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                            Wah Gwaan!
                            </button>

                            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalCenterTitle">Get to know Me!</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <ul className="list-goals">
                                        <li>I love watching soccer.</li>
                                        <li>My favorite team is Manchester United.</li>
                                        <li>I was born in Jamaica "the land of wood and water".</li>
                                    </ul>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="" data-bs-dismiss="modal">Close</button>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>

            </section>
            
        </div>

            
    </main>
        
    )
  }
  
  export default Introduction