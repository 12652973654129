import React from 'react';
import logo from '../img/RJ-2.png'
import { Link } from 'react-scroll'; //npm i react-scroll


const Navbar = () => {
    return(

    <nav class="navbar navbar-expand-lg p-0 bg-light">
        <div class="container-fluid ms-5 me-5">
            <img className='rjLogo' src={logo} role="button"  alt="RJ's Logo" />
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav " style={{fontSize: '1.2rem'}}>
                        <li className="nav-item">
                        <Link to='About' role="button" id='nav-about' className="nav-link active text-decoration-none" spy={true} smooth={true} duration={100} aria-current="page">About</Link>
                        </li>
                        <li className="nav-item">
                        <Link to='Projects' role="button" className="nav-link text-decoration-none" spy={true} smooth={true} duration={100}>Projects</Link>
                        </li>
                        <li className="nav-item">
                        <Link to='Experience' role="button" className="nav-link text-decoration-none" spy={true} smooth={true} duration={100}>Experience</Link>
                        </li>
                        <li className="nav-item">
                        <Link to='Contact' role="button" className="nav-link text-decoration-none" spy={true} smooth={true} duration={100}>Contact</Link>
                        </li>

                    </ul>
                </div>
        </div>
    </nav>
    )
}

export default Navbar
