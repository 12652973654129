import React from 'react'

const Contact = () => {

  return (
    <div id="Contact" className='container-fluid mt-5'>
        
              
                <section className='container'>
                <h1 className='pt-2 ms-5 me-5'>Contact</h1>
                <div className="text-center">
                <h2 className='text-white pt-2'>Let's connect!</h2>
                      <form 
                        method="POST"
                        name="contact" 
                        class="d-flex flex-column gap-3">

                          <input type="hidden" name="form-name" value="contact" />


                            <input required type="text" /*class="form-control"*/  name="name" placeholder="Full Name"/>
                          
                            <input required type="email" /*class="form-control"*/ name='email' placeholder="Email"/>
                          
                            <textarea required name="message" /*class="form-control"*/ rows="5" placeholder="Enter your message"></textarea>

                          <div class="pb-5">
                            <button id='submitBtn' class="btn btn-brand" type="submit">Send a message</button>
                          </div>

                      </form>

                  </div>
                </section>



              
            

    </div>
  )
}

export default Contact