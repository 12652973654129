import React from 'react'

const Experience = () => {
  return (
    <div id="Experience" className="container-fluid mt-2">

        <section className="">
            <div className="container">
            <h1 className='ms-5 me-5'>Education & Experience</h1>
  

          <div class="row gy-5 gx-5 justify-content-center">
            <div class="col-lg-4">
              <h3>Education</h3>
              <div class="row gy-4">

                  <div class="col-12">
                    <div class="card-custom rounded-4 bg-gradient">

                      <div class="card-custom-content text-white p-4 rounded-4">
                        <h4>Florida International University</h4>
                        <p>Graduated with B.S in Information Technology</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="card-custom rounded-4 bg-gradient">

                      <div class="card-custom-content text-white p-4 rounded-4">
                        <h4>Miami-Dade College</h4>
                        <p>Graduated with Associates in Information Technology</p>
                      </div>
                    </div>
                  </div>


              </div>
            </div>
            <div class="col-lg-4">
              <h3>Experience</h3>
              <div class="row gy-4">

                  <div class="col-12">
                    <div class="card-custom rounded-4">

                      <div class="card-custom-content text-white p-4">
                        <h4>Customer Support Engineer</h4>
                        <p>Kaseya</p>
                        <p>Assist customers with onboarding of the company's product or service and guide the clients through installation and updating issues as needed by answering questions and troubleshooting technical problems via phone, email, and chat</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="card-custom rounded-4">

                      <div class="card-custom-content text-white p-4">
                        <h4>Desktop Support Technician</h4>
                        <p>University Of Miami Hospital</p>
                        <p>Maintained and assisted in technical upgrading of entire desktop systems, support in testing and deployment of new applications, trained and guided staff through hardware and software usage</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="card-custom rounded-4 bg-gradient">

                      <div class="card-custom-content text-white p-4 rounded-4">
                        <h4>Technologist Assistant</h4>
                        <p>Baptist Hospital of South Florida</p>
                        <p>Assisted over 30+ inpatient and outpatient cases daily for the Technicians of CT, X-ray. Totaling an average of over 500 cases monthly for the hospital</p>
                      </div>

                    </div>
                  </div>

                  

              </div>
            </div>
          </div>


            </div>
      </section>
    </div>
  )
}

export default Experience